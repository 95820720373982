
import $app from '@/plugins/modules'
import { Common } from '@/common'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IExternalMessageTemplate } from '../types';

@Component
export default class MessageTemplateDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => [] })
  readonly item: IExternalMessageTemplate | undefined;

  common = $app.module(Common);
  data: IExternalMessageTemplate | null = null;
  editing = false

  @Watch('item')
  onShow() {
    this.editing = Boolean(this.item) && Boolean(this.item!.code) && this.item!.code !== ''
    this.data = $app.clone(this.item);
  }

  get languages(): Array<string> {
    return this.common.$store.appLanguages.map(l => l.code)
  }

  get lang(): string {
    return this.common.$store.appLanguage.code
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    this.$emit('store', this.data)
  }
}
